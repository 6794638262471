@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "Myriad-Pro-Regular";
    font-style: normal;
    font-weight: normal;
    src: local("Myriad Pro Regular"),
      url("../src/assets/fonts/myriad-pro/MYRIADPRO-REGULAR.woff")
        format("woff");
  }

  @font-face {
    font-family: "Myriad-Pro-Semibold";
    font-style: normal;
    font-weight: normal;
    src: local("Myriad Pro Semibold"),
      url("../src/assets/fonts/myriad-pro/MYRIADPRO-SEMIBOLD.woff")
        format("woff");
  }

  @font-face {
    font-family: "Myriad-Pro-Bold";
    font-style: normal;
    font-weight: normal;
    src: local("Myriad Pro Bold"),
      url("../src/assets/fonts/myriad-pro/MYRIADPRO-BOLD.woff") format("woff");
  }

  h2 {
    @apply font-myriadProBold;
  }
  p {
    @apply font-myriadProRegular;
  }
}

@layer components {
  /* .stext-heading {
    @apply text-[1.5rem] text-sprimary font-bold;
  } */
  .stext-heading {
    @apply text-[30px] leading-[36px] md:text-[40px] font-myriadProBold;
  }
  .stext-sm {
    @apply text-sm leading-[24px] font-normal text-sgrey-2 font-myriadProRegular;
  }
  .stext-base {
    @apply text-base leading-[24px] font-normal text-sgrey-2 font-myriadProRegular;
  }
  .bg-gradient {
    /* background: linear-gradient( theme(colors.sprimary-200)); */
    @apply bg-gradient-to-b from-sprimary to-sprimary-200;
  }
  .flex-center {
    @apply flex justify-center items-center;
  }
  .layout-container {
    @apply px-4 md:px-[51px] lg:px-[97px];
  }

  /* for css debugging */
  .error {
    @apply border border-red-500;
  }
}

.center item_absolute {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.center_item_absolute_x {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}
.center_item_fixed {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.text-bg {
  background: linear-gradient(252.96deg, #650ebb 14.09%, #8567e7 113.24%);
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
}

.btn-bg {
  background: linear-gradient(252.96deg, #650ebb 14.09%, #8567e7 113.24%);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
}

.card-bg {
  background: linear-gradient(
    41.05deg,
    #430a7c 13.91%,
    #6d25c6 42.71%,
    #8567e7 104.57%
  );
  box-shadow: 0px 32px 56px -12px rgba(108, 44, 180, 0.4);
  /* border-radius: 17px; */
}

.add-card-bg {
  background: linear-gradient(99.97deg, #650ebb 14.47%, #8567e7 100%);
  box-shadow: 3px 6px 12px rgba(101, 14, 187, 0.25);
}

/* Scroll Bar Styling */
.app-scrollbar::-webkit-scrollbar {
  width: 10px;
}
.app-scrollbar::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  border-radius: 10px;
  background-color: #430a7c4d;
}
.app-scrollbar::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background: #650ebb;
  border-radius: 10px;
  outline: 2px solid #430a7c4d;
}
.app-scrollbar::-webkit-scrollbar-button {
  background-color: rgb(153, 86, 197);
  border-radius: 10px;
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Animation Classes */

.slide_in {
  animation: slide-in 200ms linear;
}

@keyframes slide-in {
  0% {
    transform: translateY(-10px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

.slide_in_rtl {
  animation: slide-in-rtl 500ms linear;
}

@keyframes slide-in-rtl {
  0% {
    transform: translateX(-15px); 
    opacity: 0;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}
.sanimate-fade-in {
  animation: sfade-in 500ms linear;
}

@keyframes sfade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Newly added */

/* Disable default button tap style */
button,
div {
  -webkit-tap-highlight-color: transparent;
  /* color: rgba(79, 200, 248, 0.549); */
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
